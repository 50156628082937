<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('aged_debtors.customer')}}</label>
                                <multiselect v-model="customer" :placeholder="$t('aged_debtors.customer')"
                                             label="name"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('aged_debtors.from_date')}}</label>
                                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!--begin::customer-->
        <div class="card card-custom mt-5">

            <div class="card-body">

                <div class="col-md-12 mb-5 p-0">
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10" :id="'summaryTableDetails'+filters.group_by+'Title'">
                                <h4 class="text-center">{{ $t('aged_debtors.aged_debtors')}}</h4>
                            </div>

                            <div class="col-12 mt-10" v-if="dataChartList">
                                <BarChart :data-list="dataChartList" :currency-name="currency_name"></BarChart>
                            </div>

                            <div class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <!--                                    <button class="dropdown-item" @click="convertTableToExcelReport">-->
                                    <!--                                        <i class="la la-file-excel"></i>{{$t('excel')}}-->
                                    <!--                                    </button>-->
                                    <download-excel
                                            class="dropdown-item"
                                            :fetch="getDataList"
                                            :fields="json_fields"
                                            :name="$t('aged_debtors.aged_debtors')+'.xls'">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </download-excel>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'print')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'pdf')">
                                        <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table class="table table-bordered" :id="'summaryTableDetails'+filters.group_by">
                                        <thead>

                                        <tr id="myRow">
                                            <th>{{$t('aged_debtors.client')}}</th>
                                            <th>{{$t('aged_debtors.not_overdue')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.f_0_to_30_day')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.f_31_to_60_day')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.f_61_to_90_day')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.f_91_to_120_day')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.more_than_121_day')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.sum_over_due')}} ({{currency_name}})</th>
                                            <th>{{$t('aged_debtors.total')}} ({{currency_name}})</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row2, index2) in data" :key="'c'+getRandom()+index2">
                                            <td style="display: block;word-break: break-word;">{{row2.client}}</td>
                                            <td>{{row2.not_overdue}}</td>
                                            <td>{{row2.f_0_to_30_day}}</td>
                                            <td>{{row2.f_31_to_60_day}}</td>
                                            <td>{{row2.f_61_to_90_day}}</td>
                                            <td>{{row2.f_91_to_120_day}}</td>
                                            <td>{{row2.more_than_121_day}}</td>
                                            <td>{{row2.sum_over_due}}</td>
                                            <td>{{row2.total}}</td>
                                        </tr>

                                        </tbody>
                                        <tfoot>
                                        <tr v-if="dataTotal">
                                            <td>{{$t('total')}}</td>
                                            <th>{{ dataTotal.sum_not_overdue}}</th>
                                            <th>{{ dataTotal.sum_f_0_to_30_day}}</th>
                                            <th>{{ dataTotal.sum_f_31_to_60_day}}</th>
                                            <th>{{ dataTotal.sum_f_61_to_90_day}}</th>
                                            <th>{{ dataTotal.sum_f_91_to_120_day}}</th>
                                            <th>{{ dataTotal.sum_more_than_121_day}}</th>
                                            <th>{{ dataTotal.sum_over_due}}</th>
                                            <th>{{ dataTotal.sum_total}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <!-- End Filter -->
<!--                <div class="col-12 text-center" v-if="page">-->
<!--                    <button class="btn btn-warning" @click="loadMore">-->
<!--                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>-->
<!--                    </button>-->
<!--                </div>-->
            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    // import TableToExcel from "@linways/table-to-excel";
    import BarChart from "../components/BarChart";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "index-customer-sales-report",
        components: {'BarChart': BarChart},
        data() {
            return {
                mainRoute: '/reports/customer-debtors-report/data-list',
                mainRouteChart: '/reports/customer-debtors-report/data-list-chart',
                mainRouteDependency: 'base/dependency',

                data: [],
                dataTotal: null,
                dataChartList: null,

                customers: [],

                filters: {
                    customer_id: null,
                    from_date: null,

                },
                currency_name: null,
                customer: null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('aged_debtors.client')] = 'client';
                fields[this.$t('aged_debtors.not_overdue') + ` (${this.currency_name})`] = 'not_overdue';
                fields[this.$t('aged_debtors.f_0_to_30_day') + ` (${this.currency_name})`] = 'f_0_to_30_day';
                fields[this.$t('aged_debtors.f_31_to_60_day') + ` (${this.currency_name})`] = 'f_31_to_60_day';
                fields[this.$t('aged_debtors.f_61_to_90_day') + ` (${this.currency_name})`] = 'f_61_to_90_day';
                fields[this.$t('aged_debtors.f_91_to_120_day') + ` (${this.currency_name})`] = 'f_91_to_120_day';
                fields[this.$t('aged_debtors.more_than_121_day') + ` (${this.currency_name})`] = 'more_than_121_day';
                fields[this.$t('aged_debtors.sum_over_due') + ` (${this.currency_name})`] = 'sum_over_due';
                fields[this.$t('aged_debtors.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },

        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },

        },
        methods: {
            // convertTableToExcelReport() {
            //     TableToExcel.convert(document.getElementById('summaryTableDetails'+this.filters.group_by), {
            //         name: this.$t('aged_debtors.aged_debtors') + '.xlsx',
            //         sheet: {
            //             name: 'Sheet 1'
            //         }
            //     });
            // },
            getDataList() {
                this.setReportLog('excel', 'aged debtors');
                let _data = [...this.data];
                _data.push({
                    client: this.$t('total'),
                    not_overdue: this.dataTotal.sum_not_overdue,
                    f_0_to_30_day: this.dataTotal.sum_f_0_to_30_day,
                    f_31_to_60_day: this.dataTotal.sum_f_31_to_60_day,
                    f_61_to_90_day: this.dataTotal.sum_f_61_to_90_day,
                    f_91_to_120_day: this.dataTotal.sum_f_91_to_120_day,
                    more_than_121_day: this.dataTotal.sum_more_than_121_day,
                  sum_over_due: this.dataTotal.sum_over_due,
                    total: this.dataTotal.sum_total,
                });
                return _data;
            },
            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.user = response.data.data;
                    this.currency_name = response.data.data.currency_name;
                    if (this.user) {
                        this.user_name = this.user.name;
                    }
                });
            },

            // selectFilter() {
            //     this.getData();
            // },

            doFilter() {
                this.page = 1;
                this.getDataChart();
                this.getData();
            },
            resetFilter() {
                this.customer = null;

                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.getDataChart();
                this.getData();

            },
            dataReport() {
                return this.data;
            },
            loadMore() {
                this.page = this.page? (this.page + 1) : 1;
                this.getData();
                this.getDataChart();
            },
            getData() {
                ApiService.get(this.mainRoute, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data = response.data.data.data;
                    this.dataTotal = response.data.data.total;
                }).then().catch((error) => {this.$errorAlert(error)});
            },

            getDataChart() {
                ApiService.get(this.mainRoute, {params: {...this.filters, page:(this.page? this.page : 1), data_type: 'chart'}}).then((response) => {
                    // ApiService.get(this.mainRoute+'?data_type=chart', {params: {...this.filters}}).then((response) => {
                    // this.dataChartList = response.data;
                    this.dataChartList = {
                        labels: [
                            this.$t('aged_debtors.not_overdue'),
                            this.$t('aged_debtors.f_0_to_30_day'),
                            this.$t('aged_debtors.f_31_to_60_day'),
                            this.$t('aged_debtors.f_61_to_90_day'),
                            this.$t('aged_debtors.f_91_to_120_day'),
                            this.$t('aged_debtors.sum_over_due'),
                        ],
                        total: response.data.data.total
                    };

                });
            },


            getCustomers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },

            printData(tableId, type) {
                this.setReportLog('pdf', 'aged debtors');

                // let divToPrint = document.getElementById(tableId);
                // let divTitleToPrint = document.getElementById(tableId + 'Title');
                // let newWin = window.open("");
                // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                // setTimeout(() => {
                //     newWin.print();
                //     newWin.close();
                // }, 100)
             exportPDFInnerAction(tableId , type, this.$t('aged_debtors.aged_debtors'), this.$t('aged_debtors.aged_debtors'));
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"),route:'/clients/reports'}, {title: this.$t("MENU.aged_debtors")}]);

            this.getAuthUser();

        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>